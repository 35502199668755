<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    :custom-query="{congress: congressID}"
    authority="Congress_Management_Congress_Room"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './roomsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      entity: {
        congressTelephones: [],
      },
      entityLoaded: false,
      congressOptions: [],
    }
  },
  computed: {
    congressID() {
      return this.$route.params.id
    },
    config() {
      return {
        store: storeModule,
        module: 'congress-rooms',
        endpoint: 'congress-rooms',
        route: 'congress-rooms',
        title: {
          single: this.$t('Congress Room'),
          plural: this.$t('Congress Rooms'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formFields() {
      return [
        {
          id: 'congress',
          type: 'hidden',
          initVal: { id: this.congressID },
          hidden: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
          rules: 'min:3',
        },
        {
          id: 'description',
          type: 'text',
          label: this.$t('description'),
        },
        {
          id: 'ordering',
          type: 'text',
          label: this.$t('ordering'),
          required: true,
          initVal: 1,
        },
        {
          id: 'colorHex',
          type: 'colorpicker',
          label: this.$t('colorHex'),
          rules: 'min:6',
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
          initVal: true,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCongresses')
      .then(response => {
        this.congressOptions = response.data
      })
  },
}
</script>
